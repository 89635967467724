import React from "react";

// React
import { Disclosure } from "@headlessui/react";

// SVG
import Backdrop from "../../images/resources/how-it-works/backdrop.inline.svg";

// Images
import TempPng from "../../images/resources/how-it-works/lady-typing.png";

// Heroicons
import { ChevronDownIcon } from "@heroicons/react/outline";
import { CheckIcon, PlayIcon } from "@heroicons/react/solid";

// Shared Components
import Footer from "../../components/shared/footer/Footer";
import Header from "../../components/shared/header/Header";
import Seo from "../../components/seo";

const onDemand = [
  {
    heading: "Team member usage reports",
    description: "Straight to your inbox!",
    link: "",
  },
  {
    heading: "Central Station History",
    link: "https://www.kirkwork.co",
  },
  {
    heading: "ON/Off Test",
    link: "https://www.kirkwork.co",
  },
  {
    heading: "Advanced Testing Options",
    link: "https://www.kirkwork.co",
  },
  {
    heading: "Edit Site information",
    description: "Including phone numbers and email addresses",
    link: "https://www.kirkwork.co",
  },
  {
    heading: "Edit device information",
    link: "https://www.kirkwork.co",
  },
  {
    heading: "Edit configuration (zones)",
    link: "https://www.kirkwork.co",
  },
  {
    heading: "Edit contact information",
    description: "Including phone numbers and email addresses",
    link: "https://www.kirkwork.co",
  },
];
const faqs = [
  {
    question:
      "How do I know if my central station is compatible with Tech Tester?",
    answer: `Please take a look at our list of currently compatible central stations at alarmtechtester.com/compatible-central-stations.`,
  },
  {
    question: "What do I need to setup an account with Tech Tester?",
    answer:
      "All you need to get started with Tech Tester is a valid Central Station log-in with the appropriate permissions allowed. After you link your credentials to Tech Tester, you are all ready to get started!",
  },
  {
    question: "I use multiple central stations. Does Tech Tester allow that?",
    answer:
      "Absolutely. Tech Tester will allow you to link as many central stations to your account as you need.",
  },
  {
    question:
      "My free trial has expired, how do I continue to use Tech Tester?",
    answer:
      "To continue using Tech Tester, please enter your payment details within your account screen. If you need help with payments, please feel free to reach out to our support team, who will gladly assist in getting you back up and running with Tech Tester.",
  },
  {
    question: "When will my credit card be billed for Tech Tester?",
    answer:
      "Your credit card will be billed on the 1st of each month. If you joined late in the month, your credit card charge will reflect that. You will pay a pro-rated amount, only for the time you were using Tech Tester.",
  },
  {
    question:
      "I would like one of my technicians to have full access. Is that possible?",
    answer:
      "Absolutely. With Tech Tester, you are in full control over how much (or how little) access your technicians have. You can change the access levels at any time.",
  },
];

const steps = [
  {
    name: "Create your free account",
    description:
      "Build your company profile, and upload your logo for beautiful reports branded for your company.",
    status: "complete",
  },
  {
    name: "Configure your central stations",
    description:
      "Link your central station credentials to Tech Tester, assign default central stations, and review your permissions.",
    status: "current",
  },
  {
    name: "Invite your team members",
    description: `Link your account with your team members' accounts so you can control their access levels.`,
    status: "upcoming",
  },
  {
    name: "Assign team members permissions and access times",
    description:
      "Tech Tester utilizes the central station stages API for secure communication. Logins and credentials are completely transparent to your technicians. Once they login to Tech Tester, they see the accounts that are in their work queue, that is it!",
    status: "upcoming",
  },
  {
    name: "Build your work queues and manage your team",
    description:
      "Utilize Tech Tester’s internal messaging system to quickly communicate with your team members. No more texting to personal phones. Keep your data secure with Tech Tester!",
    status: "upcoming",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function HowItWorks() {
  return (
    <div>
      <Seo
        title="How it works"
        description="Learn how Tech Tester works, and how it can make your life easier."
      />
      <Header />
      {/* Hero section */}
      <div className="relative bg-myGray-300 py-24">
        <div
          className={
            "relative z-10 pt-10 px-10 mx-auto max-w-6xl grid grid-cols-1 md:grid-cols-2 gap-10"
          }
        >
          <div className={"my-auto text-left "}>
            <h1 className={"md:text-5xl text-4xl text-myGray-600 font-bold"}>
              Product Education
            </h1>
            <h3 className={"text-myGray-600 text-lg pt-10"}>
              Ready to get started with Tech Tester? <br />
              We are here to help - every step of the way.
            </h3>
          </div>
          <div className={"m-auto relative"}>
            <img className="z-10 relative" src={TempPng} alt="Temp Item" />
          </div>
        </div>
        <Backdrop
          className={
            "fill-myGray-250 object-scale-down absolute z-2 top-36 md:top-0 right-10 md:right-52 lg:right-96 h-80 w-auto"
          }
        />
      </div>

      {/* Alternating Feature Sections */}
      <div className="relative overflow-hidden relative">
        <div
          aria-hidden="true"
          className="absolute z-2 inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"
        />

        {/* Split section */}
        <div className="bg-white">
          <div className="max-w-5xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
            <div className={"grid grid-cols-1 md:grid-cols-2 gap-10"}>
              {/* Progress How To */}
              <div className={"flex-col"}>
                <h1 className={"font-bold text-3xl mb-10"}>Getting Started</h1>
                <nav aria-label="Progress">
                  <ol className="overflow-hidden">
                    {steps.map((step, stepIdx) => (
                      <li
                        key={step.name}
                        className={classNames(
                          stepIdx !== steps.length - 1 ? "pb-10" : "",
                          "relative"
                        )}
                      >
                        {step.status === "complete" ? (
                          <>
                            {stepIdx !== steps.length - 1 ? (
                              <div
                                className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-navy"
                                aria-hidden="true"
                              />
                            ) : null}
                            <a
                              href={step.href}
                              className="relative flex items-start group"
                            >
                              <span className="h-9 flex items-center">
                                <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-navy rounded-full group-hover:bg-navy-light">
                                  <CheckIcon
                                    className="w-5 h-5 text-white"
                                    aria-hidden="true"
                                  />
                                </span>
                              </span>
                              <span className="ml-4 min-w-0 flex flex-col">
                                <span className="text-xs font-semibold tracking-wide uppercase">
                                  {step.name}
                                </span>
                                <span className="text-sm text-gray-500">
                                  {step.description}
                                </span>
                              </span>
                            </a>
                          </>
                        ) : step.status === "current" ? (
                          <>
                            {stepIdx !== steps.length - 1 ? (
                              <div
                                className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                                aria-hidden="true"
                              />
                            ) : null}
                            <a
                              href={step.href}
                              className="relative flex items-start group"
                              aria-current="step"
                            >
                              <span
                                className="h-9 flex items-center"
                                aria-hidden="true"
                              >
                                <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-navy rounded-full">
                                  <span className="h-2.5 w-2.5 bg-navy rounded-full" />
                                </span>
                              </span>
                              <span className="ml-4 min-w-0 flex flex-col">
                                <span className="text-xs font-semibold tracking-wide uppercase text-navy">
                                  {step.name}
                                </span>
                                <span className="text-sm text-gray-500">
                                  {step.description}
                                </span>
                              </span>
                            </a>
                          </>
                        ) : (
                          <>
                            {stepIdx !== steps.length - 1 ? (
                              <div
                                className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                                aria-hidden="true"
                              />
                            ) : null}
                            <a
                              href={step.href}
                              className="relative flex items-start group"
                            >
                              <span
                                className="h-9 flex items-center"
                                aria-hidden="true"
                              >
                                <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                                  <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                                </span>
                              </span>
                              <span className="ml-4 min-w-0 flex flex-col">
                                <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">
                                  {step.name}
                                </span>
                                <span className="text-sm text-gray-500">
                                  {step.description}
                                </span>
                              </span>
                            </a>
                          </>
                        )}
                      </li>
                    ))}
                  </ol>
                </nav>

                <div className={"mt-16 mb-8 "}>
                  <h1
                    className={
                      "font-bold text-sm font-semibold tracking-wide uppercase"
                    }
                  >
                    Join us for our live training sessions.
                  </h1>
                  <p className={"text-sm text-myGray-500 mb-10"}>
                    Tuesdays 1pm - 2pm EST
                  </p>
                </div>
                <div
                  className={
                    "bg-gray-300 w-3/4 mg:w-1/2 px-10 py-2 rounded-md shadow-lg"
                  }
                >
                  <p className={"text-white text-center font-bold"}>
                    COMING SOON
                  </p>
                </div>
                <div className={"my-8"}>
                  <h1
                    className={
                      "font-bold text-sm font-semibold tracking-wide uppercase"
                    }
                  >
                    prefer to watch a recording?
                  </h1>
                  <p
                    className={"text-sm text-myGray-500 mb-10 underline"}
                  >
                    Click Here
                  </p>
                </div>
              </div>

              {/* Helpful video links */}
              <div className={""}>
                <h1 className={"font-bold text-3xl mb-10"}>
                  Additional Features
                </h1>
                <ol className="overflow-hidden">
                  {onDemand.map((demand, index) => (
                    <li
                      key={demand.heading}
                      className={classNames(
                        index !== demand.length - 1 ? "pb-4" : "",
                        "relative"
                      )}
                    >
                      <div className={""}>
                        <div
                          className={
                            "grid grid-cols-6 border-b gap-4 items-center"
                          }
                        >
                          <div className={"col-span-5"}>
                            <h1
                              className={classNames(
                                demand.description
                                  ? "font-medium text-sm font-semibold tracking-wide uppercase pt-4"
                                  : "font-medium text-sm font-semibold tracking-wide uppercase py-4"
                              )}
                            >
                              {demand.heading}
                            </h1>
                            <p className={"text-sm text-myGray-500 mb-4"}>
                              {demand.description}
                            </p>
                          </div>
                          <div className={"col-span-1"}>
                            <PlayIcon
                              className={
                                "text-navy my-4 h-12 hover:text-navy-light"
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ol>
              </div>
            </div>
          </div>
        </div>

        {/* FAQ Section */}
        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto">
              <h2 className="text-center text-3xl font-extrabold text-gray-900">
                Frequently asked questions
              </h2>
              {faqs.map((faq) => (
                <Disclosure
                  as="div"
                  key={faq.question}
                  className="pt-6 bg-myGray-300 rounded-md m-5 p-5"
                >
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                          <span className="font-medium text-gray-900">
                            {faq.question}
                          </span>
                          <span className="ml-6 h-7 flex items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? "-rotate-180" : "rotate-0",
                                "h-6 w-6 transform"
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base text-gray-500">{faq.answer}</p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
